<template>
  <div class="pages page33">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/direction3_Trotinette.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Attention intersection !</h1>
      <p>
        Sur une intersection, un utilisateur d'EDPM voit sa trajectoire coupée
        par un véhicule automobile tournant à gauche.
      </p>
      <!--<video
        controls=""
        src="https://player.vimeo.com/external/557954689.hd.mp4?s=4bd1c24a4a4b2449629f487da07eb46d6c25345d&profile_id=175"
      ></video>-->
      <miniPlayer
        src="https://player.vimeo.com/external/557954689.hd.mp4?s=4bd1c24a4a4b2449629f487da07eb46d6c25345d&profile_id=175"
        thumbnail="thumbnail-risque6.png"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <p>Attention à l'angle mort du véhicule automobile.</p>
        <p>
          Assurez-vous de disposer d'un équipement assurant une bonne
          visibilité.
        </p>
        <p>Soyez attentif à son clignotant.</p>
        <p>
          Ne jamais dépasser un véhicule automobile ou poids-lourd par la
          droite.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page33",

  components: {
    miniPlayer,
  },

  data() {
    return {
      showConseil: false,
    };
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page33 {
  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>