<template>
  <div class="pages page7">
    <div class="page_img"><img src="../assets/monoroue02.png" alt="" /></div>
    <div class="page_content">
      <h1>La monoroue ou gyroroue</h1>
      <p>
        Second engin de déplacement urbain le plus utilisé, la monoroue est,
        comme son nom l'indique, dotée d'une roue centrale.
      </p>

      <p>
        En déplacement, l'utilisateur posera ses pieds sur les étriers situés
        sur chacun de ses côtés.
        <strong
          >L'inclinaison du corps décidera de l'accélération ou de la
          décélération de l'engin</strong
        >
        grâce à une carte mère qui analysera la position du corps.
      </p>

      <p>
        Une fonction « tiltback » équipe de nombreux monocycles électriques et
        permettra à son conducteur d'être averti lorsque la vitesse maximale est
        atteinte.
      </p>

      <p>
        Peu encombrant, capable de rouler sur la plupart des revêtement de
        chaussée, cet engin est en revanche beaucoup moins accessible que la
        trottinette,
        <strong>il demande une initiation et une pratique plus assidues</strong>
        pour être maîtrisé en conditions de déplacements urbains.
      </p>

      <p>
        Attention sur une monoroue,
        <strong>il n'y a pas d'organe de freinage dédié.</strong> Le frein et
        l'accélération repose sur le même principe. Pas de freinage d'urgence
        possible ! Sur une monoroue plus encore que sur une trottinette,
        l'anticipation s'impose.
      </p>
    </div>
  </div>
</template>