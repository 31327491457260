<template>
  <div class="pages page6">
    <div class="page_img"><img src="../assets/trotinette06.png" alt="" /></div>
    <div class="page_content">
      <h2>Ses atouts :</h2>
      <ul>
        <li><span><strong>facilité d'usage et de stockage,</strong></span></li>
        <li><span><strong>vitesse (25 km/h)</strong> avec des efforts moindres à fournir qu'à vélo,</span></li>
        <li><span><strong>autonomie suffisante</strong> pour des déplacements quotidiens en ville,</span></li>
        <li><span><strong>des tarifs en diminution à l'achat,</strong></span></li>
        <li><span><strong>adaptation aux déplacements intermodaux.</strong> C'est un engin d'autant plus
          facile à utiliser dans des trajets intermodaux qu'existe aujourd'hui
          une offre de service en free floating.
        </span></li>
      </ul>
      <p>
        Le stationnement anarchique des trottinettes dans les agglomérations a
        amené les pouvoirs publics à réglementer leur usage et à limiter le
        nombre d'opérateurs autorisés à déployer leur offre d'engins dans
        l'espace public.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.page6 {
 
 .page_content {
   padding-top: 5vh;
 }
}
</style>