<template>
  <div class="pages page31">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/direction5_Trotinette.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Sortie de parking, danger !</h1>
      <p>
        Un EDPM circule sur le trottoir et se trouve surpris par un véhicule
        sortant d'un parking.
      </p>
      <!--<video
        controls=""
        src="https://player.vimeo.com/external/557956701.hd.mp4?s=020e918590c1ce2b6446e08979d59c73a65921c6&profile_id=175"
      ></video>-->
      <miniPlayer
        src="https://player.vimeo.com/external/557956701.hd.mp4?s=020e918590c1ce2b6446e08979d59c73a65921c6&profile_id=175"
        thumbnail="thumbnail-risque4.png"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <p>
          Dans ce cas de figure, l'utilisateur de monoroue est en infraction car
          il circule sur le trottoir.
        </p>
        <p>
          Les sorties de parking des véhicules doivent être anticipées car le
          véhicule n'est pas toujours visible jusqu'à son arrivée sur la voie
          publique.
        </p>
        <p>
          Une bonne visibilité est un atout pour le conducteur d'EDPM qui doit
          également ralentir face à une sortie de parking comme lors de tout
          croisement.
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page31",

  components: {
    miniPlayer,
  },

  data() {
    return {
      showConseil: false,
    };
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page31 {
  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>