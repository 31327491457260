<template>
  <div class="pages page22">
    <div class="page_img"><img src="../assets/tmp06.png" alt="" /></div>
    <div class="page_content">
      <h1>Législation : où et comment circuler</h1>
      <p><strong>Si la règle s'impose, des dispositions locales peuvent faire figure d'exception.</strong></p>
      <p><strong>En effet, l’autorité investie du pouvoir de police</strong> peut interdire la circulation des EDP sur certaines sections de voiries qui leur sont théoriquement ouvertes (en motivant par des nécessités de sécurité routière, de fluidité ou de commodité de passage) ou, inversement, de les autoriser sur des routes limitées à 80 km/h. </p>
      <p>Dans ce dernier cas, le conducteur de l’EDPM devra :</p>
      <ul>
        <li><strong>porter un casque homologué et attaché ;</strong></li>
        <li><strong>porter un équipement de haute visibilité ou rétro-réfléchissant ;</strong></li>
        <li><strong>circuler de jour comme de nuit avec ses feux de position allumés</strong>et porter sur lui un dispositif d’éclairage complémentaire non clignotant (dont les caractéristiques seront fixées par arrêté).</li>
      </ul> 
      <p>En matière de port du casque, lorsque l’infraction sera commise par un mineur, la responsabilité incombera au majeur l’accompagnant.</p> 
    </div>
  </div>
</template>