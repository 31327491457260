<template>
  <div class="pages page1">
    <div class="page_img"><img src="../assets/tmp05.png" alt="" /></div>
    <div class="page_content">
      <h1>Un mode de déplacement en forte de croissance</h1>
      <p>
        EDPMs, <strong>Engins de déplacement personnel motorisés.</strong> Ils
        sont nouveaux venus dans le code de la route mais déjà bien présents sur
        les voies publiques.
      </p>
      <p>
        Dans toutes les agglomérations et tout particulièrement en
        Ile-de-France, l'usage des engins de déplacement personnel motorisés,
        tout particulièrement des trottinettes électriques, a connu une
        <strong>très forte augmentation depuis 2015.</strong>
      </p>
      <p>
        Entre 2017 et 2018, les ventes de trottinettes électriques ont augmenté
        de <strong>+129%</strong> (102 000 à 232 000 entre 2017 et 2018).
      </p>
      <!--<video
        controls="" preload="auto" poster="../assets/thumb-intro.png"
        src="https://player.vimeo.com/external/557955269.hd.mp4?s=aca0e2816e07264e3666b6488652c5fa0c7e3929&profile_id=175"
      ></video>-->
      <miniPlayer src="https://player.vimeo.com/external/557955269.hd.mp4?s=aca0e2816e07264e3666b6488652c5fa0c7e3929&profile_id=175" thumbnail="thumbnail-intro.png"></miniPlayer>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page1",
  
  components: {
    miniPlayer,
  },

};
</script>

<style lang="scss" scoped>
.page1 .page_img {
  img {
    object-fit:scale-down;
    object-position: 8vw 0vh;
  }
}
</style>