<template>
  <div class="pages page23">
    <div class="page_img"><img src="../assets/tmp06.png" alt="" /></div>
    <div class="page_content">
      <h1>Infractions et Contraventions</h1>
      <h2>En matière d’équipement du véhicule</h2>
      <p>
        Les infractions relatives aux
        <strong>obligations d’équipement des EDPMs</strong>seront réprimées par
        des contraventions de 1ère classe : absence de dispositif de freinage
        efficace, des feux et catadioptres, de l’avertisseur sonore.
      </p>
      <h2>En matière d’équipement des usagers</h2>
      <p>
        L’infraction relative au non-port des
        <strong>équipements de visibilité la nuit</strong>est punie par une
        contravention de 2ème classe.
      </p>
      <p>
        Il en est de même pour le port de ces équipements de jour et celui des
        éclairages complémentaires lorsqu’il est fait application des
        dispositions permettant de circuler sur des voies limitées à 80 km/h.
      </p>
      <p>
        Toutes les infractions relatives au port du
        <strong>casque</strong>obligatoire relèvent de l’amende de la 4ème
        classe.
      </p>
    </div>
  </div>
</template>