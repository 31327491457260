<template>
  <div class="pages page36">
    <div class="equipmentMenu">
      <ul>
        <li @click="slideSelect = 1">
          <span :class="slideSelect == 1 ? 'select' : ''"></span>Sonnette
        </li>
        <li @click="slideSelect = 2">
          <span :class="slideSelect == 2 ? 'select' : ''"></span>Catadioptres
        </li>
        <li @click="slideSelect = 3">
          <span :class="slideSelect == 3 ? 'select' : ''"></span>Feux de
          position
        </li>
        <li @click="slideSelect = 4">
          <span :class="slideSelect == 4 ? 'select' : ''"></span>Dispositif de
          freinage
        </li>
        <li @click="slideSelect = 5">
          <span :class="slideSelect == 5 ? 'select' : ''"></span>Équipement de
          visibilité
        </li>
        <li @click="slideSelect = 6">
          <span :class="slideSelect == 6 ? 'select' : ''"></span>Casque
        </li>
        <li @click="slideSelect = 7">
          <span :class="slideSelect == 7 ? 'select' : ''"></span>Soyez prudent !
        </li>
        <li @click="slideSelect = 8">
          <span :class="slideSelect == 8 ? 'select' : ''"></span>Hors
          agglomération
        </li>
      </ul>
    </div>
    <div class="equipmentPictures" @click="slideSelect = 0">
      <img
        src="../assets/Trottinette-fond.png"
        alt=""
        id="equipmentPicture1"
        :class="slideSelect != 0 && slideSelect <= 6 ? 'soften' : ''"
      />
      <img
        src="../assets/Trottinette-sonnette.png"
        alt=""
        id="equipmentPicture2"
        v-show="slideSelect == 1"
      />
      <img
        src="../assets/Trottinette-feux.png"
        alt=""
        id="equipmentPicture3"
        v-show="slideSelect == 3"
      />
      <img
        src="../assets/Trottinette-freins.png"
        alt=""
        id="equipmentPicture4"
        v-show="slideSelect == 4"
      />
      <img
        src="../assets/Trottinette-catadioptres.png"
        alt=""
        id="equipmentPicture5"
        v-show="slideSelect == 2"
      />
      <img
        src="../assets/Trottinette-casque.png"
        alt=""
        id="equipmentPicture6"
        v-show="slideSelect == 6"
      />
      <img
        src="../assets/Trottinette-visibilite.png"
        alt=""
        id="equipmentPicture7"
        v-show="slideSelect == 5"
      />
    </div>
    <div class="equipmentDescription">
      <div id="equipmentContent1" v-show="slideSelect == 1">
        <h1>Avertisseur sonore</h1>
        <p>
          Votre engin doit être équipé d'un avertisseur sonore pour vous
          permettre d'alerter de votre présence les autres usagers.
        </p>
      </div>
      <div id="equipmentContent2" v-show="slideSelect == 2">
        <h1>Catadioptres</h1>
        <p>
          Votre engin doit être équipé de dispositifs rétro-réﬂechissants, blanc
          à l'avant, rouge à l'arrière et orange sur les côtés.
        </p>
      </div>
      <div id="equipmentContent3" v-show="slideSelect == 3">
        <h1>Feux de position</h1>
        <p>
          Votre engin doit être équipé de feux de position, blanc à l'avant et
          rouge à l'arrière.
        </p>
      </div>
      <div id="equipmentContent4" v-show="slideSelect == 4">
        <h1>Freins</h1>
        <p>
          Votre engin doit être équipé d'un dispositif de freinage efficace.
        </p>
      </div>
      <div id="equipmentContent5" v-show="slideSelect == 5">
        <h1>Équipement de visibilité</h1>
        <p>
          La nuit ou de jour en cas de visibilité insuffisante : gilet de haute
          visibilité ou équipement auto-réfléchissant.
        </p>
        <p>
          Il est possible de porter en complément un dispositif d'éclairage non
          éblouissant et non clignotant.
        </p>
      </div>
      <div id="equipmentContent6" v-show="slideSelect == 6">
        <h1>Casque</h1>
        <p>Il est préférable de porter un casque.</p>
        <p>
          De plus il est conseillé d'adjoindre comme protection des gants, des
          genouillères et des coudières
        </p>
      </div>
      <div id="equipmentContent7" v-show="slideSelect == 7">
        <h1>Soyez prudent !</h1>
        <p>
          Pensez à votre sécurité mais aussi à
          <strong>celle des autres.</strong>
        </p>
        <p>
          L'utilisateur doit disposer d'une
          <strong>assurance responsabilité civile.</strong>
        </p>
        <p>Votre engin ne doit pas dépasser les <strong>25 km/h.</strong></p>
        <p>
          <strong>Ne transportez pas de passager !</strong> Votre engin est
          destiné à usage exclusivement personnel.
        </p>
        <p>
          Le stationnement sur un trottoir n’est possible que
          <strong>s’il ne gêne pas la circulation des piétons.</strong>
        </p>
        <p>
          Les enfants de <strong>moins de 12 ans</strong> n’ont pas le droit de
          conduire ces engins.
        </p>
      </div>
      <div id="equipmentContent8" v-show="slideSelect == 8">
        <h1>Hors agglomération</h1>
        <p>
          L'utilisateur peut circuler sur les pistes cyclables et sur les voies
          vertes.
        </p>
        <p>
          Le maire peut autoriser la circulation des EDPMs sur les routes dont
          la vitesse maximale autorisée est de 80 km/h.
        </p>
        <p>Dans ce cas, l'utilisateur doit :</p>
        <ul>
          <li>Porter un casque</li>
          <li>Se vêtir d'un équipement rétro-réfléchissant</li>
          <li>Rouler avec les feux de positions allumés</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page36",

  data() {
    return {
      slideSelect: 0,
    };
  },

  methods: {
    goto: function (page) {
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>



<style lang="scss" scoped>
.page36 {
  background-color: white;
  position: initial;

  div {
    display: inline-block;
    height: 71vh;
    margin-top: 2vh;
  }

  .equipmentMenu {
    float: left;
    margin-left: 8vw;
    margin-top: 3%;

    ul {
      text-align: left;
      font-size: 3vh;
      list-style-type: none;

      li {
        cursor: pointer;
        margin: 4vh 0;

        span {
          width: 1vw;
          height: 1vw;
          display: inline-block;
          margin: 0 0.5vw;

          &.select {
            background-image: url("../assets/puce.png");
            background-repeat: no-repeat;
            background-size: 1vw;
          }
        }
      }
    }
  }

  .equipmentPictures {
    width: 25vw;
    height: 71vh;
    position: absolute;
    top: 0;
    left: 40vw;

    img {
      height: 71vh;
      position: absolute;
      top: 0;
      left: 0;

      &.soften {
        opacity: 0.12;
      }
    }
  }

  .equipmentDescription {
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    text-align: left;

    div {
      padding: 0 2vw;

      h1 {
        font-size: 2vw;
      }

      p {
        font-size: 1.4vw;
        font-family: MyriadPro;
      }

      li {
        font-size: 1.5vw;
        font-family: MyriadPro;
        list-style-image: url("../assets/puce-30x30.png");

        &::marker {
          //font-size: 4vw;
          line-height: 6vh;
        }
      }
    }
  }
}
</style>