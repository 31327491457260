<template>
  <div class="pages page35">
    <div class="page_content">
      <h1>L'équipement</h1>
      <p>
        Depuis l'entrée des EDPM dans le code de la route,
        <strong
          >l'équipement du véhicule et de son conducteur est réglementé.</strong
        >
      </p>
      <p>
        Si certains équipements sont donc aujourd'hui obligatoires, d'autres
        sont fortement conseillés pour augmenter la visibilité de l'utilisateur
        ou mieux le protéger en cas de chute.
      </p>
    </div>
    <div class="page_videos">
      <!--<video
        id="video1"
        controls=""
        controlslist="nodownload"
        disablePictureInPicture
        src="https://player.vimeo.com/external/557957145.hd.mp4?s=5327d012e12026bcea34074c08d9127eca948e47&profile_id=175"
        @ended="onEnd()"
        @play="onPlay($event)"
        ref="video1"
      ></video>
      <video
        id="video2"
        controls=""
        controlslist="nodownload"
        disablePictureInPicture
        src="https://player.vimeo.com/external/557954996.hd.mp4?s=df4a085da3637ef6631f4c12645c9cb8f17859de&profile_id=175"
        @ended="onEnd()"
        @play="onPlay($event)"
        ref="video2"
      ></video>-->
      <miniPlayer id="video1"
        src="https://player.vimeo.com/external/557957145.hd.mp4?s=5327d012e12026bcea34074c08d9127eca948e47&profile_id=175"
        thumbnail="thumbnail-equipment1.png"
      ></miniPlayer>
      <miniPlayer id="video2"
        src="https://player.vimeo.com/external/557954996.hd.mp4?s=df4a085da3637ef6631f4c12645c9cb8f17859de&profile_id=175"
        thumbnail="thumbnail-equipment2.png"
      ></miniPlayer>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page35",

  components: {
    miniPlayer,
  },

  /*data() {
    return {
      isFullScreen: false,
    };
  },*/

  /*methods: {
    onEnd: function () {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    },

    onPlay: function (e) {
      if (e.target.requestFullscreen) {
        e.target.requestFullscreen();
      } else if (e.target.webkitRequestFullscreen) {
        // Safari 
        e.target.webkitRequestFullscreen();
      } else if (e.target.msRequestFullscreen) {
        // IE11 
        e.target.msRequestFullscreen();
      }
    },

    onFullScreen: function (e) {
      console.log(e);
      if (document.fullscreenElement) {
        console.log("Fullscreen enabled");
        e.target.play();
        //this.isFullScreen = true;
      } else {
        console.log("Fullscreen disabled");
        //this.isFullScreen = false;
        e.target.pause();
      }

      console.log(this);
    },
  },

  created: function () {
    console.log("Created");
    document.addEventListener("webkitfullscreenchange", this.onFullScreen);
    document.addEventListener("mozfullscreenchange", this.onFullScreen);
    document.addEventListener("fullscreenchange", this.onFullScreen);
  },*/
};
</script>

<style lang="scss" scoped>
.page35 {
  height: 71vh;
  margin-top: 2vh;

  .page_content {
    float: left;
    margin-left: 4vw;
    width: 60vw;
  }

  .page_videos {
    width: 35vw;
    height: 71vh;
    position: absolute;
    right: 0;
  }

  #video1 {
    position: absolute;
    top: 6vh;
  }

  #video2 {
    position: absolute;
    bottom: 6vh;
  }
}
</style>