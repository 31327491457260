<template>
  <div class="pages page10">
    <div class="page_img"><img src="../assets/skateboard02.png" alt="" /></div>
    <div class="page_content">
      <h1>Le skateboard électrique</h1>
      <p>
        Le skateboard électrique est une déclinaison d'un skateboard
        généralement long, équipé d'un moteur situé le plus souvent sous la
        planche.
      </p>

      <p>
        Les modèles utilisés pour se déplacer en ville disposent d'une
        télécommande permettant d'actionner l'accélération et le freinage.
      </p>

      <p>
        Le skate board électrique dispose d'une autonomie plus faible que la
        trottinette et supporte difficilement les revêtements dégradés.
      </p>

      <p>
        <strong
          >Son comportement au freinage le rend dangereux en conditions de
          circulation urbaine dense.</strong
        >
      </p>
    </div>
  </div>
</template>