<template>
  <div class="pages page34">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/direction2_Trotinette.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Traversée sur passage piétons interdite</h1>
      <p>
        Un EDPM utilise un passage piéton situé à une intersection pour
        traverser la voie et éviter de s'arrêter au feu rouge.
      </p>
      <!--<video
        controls=""
        src="https://player.vimeo.com/external/557957379.hd.mp4?s=13bfbf77482a396ceafdf4b1530f563ff26d1849&profile_id=175"
      ></video>-->
      <miniPlayer
        src="https://player.vimeo.com/external/557957379.hd.mp4?s=13bfbf77482a396ceafdf4b1530f563ff26d1849&profile_id=175"
        thumbnail="thumbnail-risque7.png"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <p>
          Pour les EDPMs comme pour les vélos, toute voie piétonne est interdite
          et l'usager doit mettre pied à terre pour emprunter voies et passages
          piétons.
        </p>
        <p>
          Pour quitter la chaussée et emprunter les voies et passages piétons,
          l'utilisateur d'EDPM doit marcher à côté de son véhicule.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page34",

  components: {
    miniPlayer,
  },

  data() {
    return {
      showConseil: false,
    };
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page34 {
  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>