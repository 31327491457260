<template>
  <div class="pages page5">
    <div class="page_img"><img src="../assets/tmp06.png" alt="" /></div>
    <div class="page_content">
      <h1>La trottinette électrique</h1>
      <p>Parmi l'ensemble des EDPMs, la trottinette électrique est reine. Après
      avoir longtemps occupé le rôle de jouet pour enfant, elle avait gagné son
      statut de véhicule sur de courts trajets à partir des années 90 et une
      utilisation sportive et de loisirs principalement chez les adolescents.</p>
      <p>Depuis qu'elle est équipée d'un moteur, d'une batterie et d'une commande
      d'accélération, ce statut a fortement évolué. Elle a conquis les espaces
      urbains et demeure de très loin l'EDPM le plus utilisé pour les
      déplacements en ville.</p>
    </div>
  </div>
</template>