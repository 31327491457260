<template>
  <div class="pages page11">
    <div class="page_img"><img src="../assets/tmp04.png" alt="" /></div>
    <div class="page_content">
      <h1>Une nouvelle législation spécifique pour les EDPMs</h1>
      <p>
        Jusqu'en octobre 2019, les trottinettes, monoroues, skateboards
        électriques et gyropodes n'appartenaient à aucune catégorie du code de
        la route !
        <strong
          >Leur circulation dans l'espace public n'était donc ni réglementée, ni
          autorisée.</strong
        >
      </p>

      <p>
        Face à la croissance du nombre d'EDPMs et à l'observation de
        comportements dangereux, une réglementation dédiée s'imposait pour
        assurer la sécurité de leurs utilisateurs et des autres usagers.
      </p>

      <p>
        <strong>Avec le décret publié le 25 octobre 2019</strong>, un vide
        juridique a enfin été comblé pour encadrer ces modes de déplacement bien
        spécifiques.
      </p>
    </div>
  </div>
</template>