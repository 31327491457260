<template>
  <div id="credit">
    <div class="logo">
      <img src="../assets/gmf-logo.png" alt="" />
    </div>
    <div class="advisor">
      <h2>Conseiller technique</h2>
      <p>Christophe Barbien</p>
      <p class="function">Brigadier-chef</p>
    </div>
    <div class="advisor">
      <h2>Coordination générale</h2>
      <p>Thierry Legay</p>
      <p class="function">Commandant de Police</p>
    </div>
    <div class="complement">
      <p><span class="titre">Crédits photos : </span>Shutterstock</p>
      <p>
        <span class="titre">Droits de distribution et production : </span>GMF
        Assurances
      </p>
      <p>
        <span class="titre">Concepteur et réalisateur de l’outil : </span>Pellicam
      </p>
      <p>
        <span class="titre">Sources : </span>Observatoire national
        interministériel de la sécurité routière, Ministère de l’Intérieur, 6-t
        Bureau de recherche, Délégation de la Sécurité routière
      </p>
    </div>
    <div class="copyright">
      <p>&copy;2021 - Pellicam</p>
      <img src="../assets/Logo_CartouchePellicam.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showCredits: Boolean,
  },
};
</script>

<style lang="scss">

#credit {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #7dc07d;
  z-index: 9999;
  opacity: 0.95;

  .logo {
    padding-top: 10vh;

    img {
      border: white solid 1px;
      width: 10vw;
    }
  }

  .advisor {
    display: inline-block;
    padding: 4vh 5vw;
    font-size: 2vw;

    .function {
      font-size: 1.2vw;
    }

    p {
      color: white;
      margin: 0;
    }
  }

  h2 {
    color: #3f3853;
    margin: 2vh 0 ;
  }

  .complement {
    text-align: left;
    margin: 0 15vw;
    font-size: 1.2vw;

    .titre {
      color: #3f3853;
    }

    p {
      margin: 1vh 0;
      color: white;
    }
  }

  .copyright {

    p {
      font-size: 1.6vw;
    }

    img {
      float: right;
      margin-right: 4vw;
    }
  }
}
</style>
