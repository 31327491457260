<template>
  <div class="pages page4">
    <div class="page_content">
      <h1>Diversité des EDPMs</h1>
      <p>
        Ils existent aujourd'hui plusieurs types d'EDPM qui circulent dans nos
        agglomérations. Les spécificités de chacun d'entre eux les attachent à
        un usage plus ou moins large, évoluant
        <strong
          >entre pratique de loisirs et outil de déplacement quotidien.</strong
        >
      </p>
      <p>
        Si la trottinette est la plus largement utilisée dans nos
        agglomérations, elle coexiste avec
        <strong
          >la monoroue, le gyropode, l'hoverboard, ou encore le skateboard
          électrique.</strong
        >
        Mais tous ces engins rentrent bien dans la même catégorie où la même
        réglementation s'applique.
      </p>
      <div class="page_button">
        <img src="../assets/trotinette01.png" alt="" @click="goto(5)" />
        <img src="../assets/monoroue03.png" alt="" @click="goto(7)" />
        <img src="../assets/gyropode02.png" alt="" @click="goto(8)" />
        <img src="../assets/hoverboard.png" alt="" @click="goto(9)" />
        <img src="../assets/skateboard.png" alt="" @click="goto(10)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page4",

  methods: {
    goto: function (page) {
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss" scoped>
.page4 {
  .page_content {
    margin-left: 5vw;
  }

  .page_button {
    img {
      display: inline-block;
      width: 10vw;
      margin: 4vw 4vw;
      cursor: pointer;
    }
  }
}
</style>