<template>
  <div class="pages page9">
    <div class="page_img"><img src="../assets/hoverboard.png" alt="" /></div>
    <div class="page_content">
      <h1>L'hoverboard</h1>
      <p>
        L'hoverbard est un gyropode sans manche de conduite. Il réagira lui
        aussi à l'inclinaison du corps pour accélérer ou décélérer.
      </p>

      <p>
        Plus lent, moins maniable et disposant d'une plus faible autonomie, il
        est moins usité dans les déplacements urbains et
        <strong>se destine davantage à un usage loisirs pour les plus jeunes.</strong>
      </p>
    </div>
  </div>
</template>