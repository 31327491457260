<template>
  <div class="pages page25">
    <div class="page_img"><img src="../assets/tmp06.png" alt="" /></div>
    <div class="page_content">
      <p>
        Par analogie avec tout véhicule, le fait de circuler sur un trottoir
        sans y être autorisé sera réprimé par une contravention de 4ème classe.
      </p>
      <p>
        La répression des infractions relatives au non-respect de la
        signalisation (feux, sens interdit) ou à l’utilisation de distracteurs
        (téléphone, oreillettes) impliquant une amende de classe 4 est appliquée
        sans générer de retrait de points pour les conducteurs concernés.
      </p>
      <h2>Classification des contraventions</h2>
      <table>
        <thead>
          <tr>
            <th>Classe</th>
            <th>Amende forfaitaire</th>
            <th>Amende forfaitaire minorée</th>
            <th>Amende forfaitaire majorée</th>
            <th>Maxima</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>11€</td>
            <td>-</td>
            <td>33€</td>
            <td>38€</td>
          </tr>
          <tr>
            <td>2</td>
            <td>35€</td>
            <td>22€</td>
            <td>75€</td>
            <td>150€</td>
          </tr>
          <tr>
            <td>3</td>
            <td>68€</td>
            <td>45€</td>
            <td>180€</td>
            <td>450€</td>
          </tr>
          <tr>
            <td>4</td>
            <td>135€</td>
            <td>90€</td>
            <td>180€</td>
            <td>450€</td>
          </tr>
          <tr>
            <td>5</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>1500€</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page25 {
  .page_content {
    padding-top: 3vh;
  }

  table {
    border-spacing: 0;
  }

  th {
    background-color: #3f3853;
    color: #f1e966;
    border-right: #5d9ad3 solid 2px;
    padding: 1vw;

    &:last-of-type {
      border: 0;
    }
  }

  td {
    text-align: center;
    border-right: #000 solid 2px;
    padding: 1vw;
    font-size:1.2vw;
    line-height: 1.1vw;

    &:last-of-type {
      border: 0;
    }
  }

  tr {
    &:nth-child(odd) {
      background-color: #aaa;
    }
  }
}
</style>