<template>
  <div class="pages page14">
    <div class="page_img"><img src="../assets/accident.png" alt="" /></div>
    <div class="page_content">
      <h1>Des accidents de plus en plus graves et fréquents</h1>
      <p>
        Les EDPMs n'existaient pas jusqu'en 2019 comme une catégorie à part dans
        le Code de la route.
      </p>

      <p>
        Il n'existe donc pas de statistiques précises sur le nombre d'accidents
        impliquant un EDPM. <strong>En 2017</strong>, La Sécurité routière
        faisait néanmoins état de <strong>284 blessés</strong> et
        <strong>5 tués. Entre 2016 et 2017</strong>, le nombre de blessés et
        tués à trottinette et à rollers a augmenté de 23%. Les victimes de ces
        accidents étaient âgées de <strong>27 ans</strong> en moyenne pour les
        hommes et de <strong>34 ans</strong> pour les femmes.
      </p>

      <p>
        À cette date, les accidents touchaient donc déjà dans une forte
        proportion Paris et les départements de première couronne... Alors que
        les opérateurs en libre service, n'étaient pas encore en activité.
      </p>
    </div>
  </div>
</template>