<template>
  <div class="pages page21">
    <div class="page_img"><img src="../assets/tmp06.png" alt="" /></div>
    <div class="page_content">
      <h1>Législation : où et comment circuler</h1>
      <p>
        Contrairement aux cycles qui peuvent par exemple être attelés d’un
        side-car, les EDP motorisés ne seront pas autorisés à pousser ou tracter
        une charge ou un véhicule.
      </p>
      <p>
        L’usage d’EDP motorisés sur la voie publique est
        <strong>interdit aux enfants de moins de 12 ans.</strong>
      </p>
      <p>
        <strong>Le transport de passagers n’est pas autorisé</strong> sur les
        EDP motorisés.
      </p>
    </div>
  </div>
</template>