<template>
  <div class="pages page27">
    <div class="page_content">
      <div class="thumbsButton" @click="goto(28)">
        <img src="../assets/bus_Trotinette.jpg" alt="" />
        <p>Attention piétons !</p>
      </div>
      <div class="thumbsButton" @click="goto(29)">
        <img src="../assets/chaussee.jpg" alt="" />
        <p>Circulation sur trottoirs interdite</p>
      </div>
      <div class="thumbsButton" @click="goto(30)">
        <img src="../assets/portiere_Trotinette.jpg" alt="" />
        <p>Attention ouverture de portière !</p>
      </div>
      <div class="thumbsButton" @click="goto(31)">
        <img src="../assets/direction5_Trotinette.jpg" alt="" />
        <p>Sortie de parking, danger !</p>
      </div>
      <div class="thumbsButton" @click="goto(32)">
        <img src="../assets/direction4_Trotinette.jpg" alt="" />
        <p>Sortie de stationnement, danger !</p>
      </div>
      <div class="thumbsButton" @click="goto(33)">
        <img src="../assets/direction3_Trotinette.jpg" alt="" />
        <p>Attention intersection !</p>
      </div>
      <div class="thumbsButton" @click="goto(34)">
        <img src="../assets/direction2_Trotinette.jpg" alt="" />
        <p>Traversée passage piétons interdite</p>
      </div>
      <div class="thumbsButton">
        <!--<video
        controls=""
        src="https://player.vimeo.com/external/557954407.hd.mp4?s=bcae68fd2d18867df4652c99a291b02b8a4f50c5&profile_id=175"
      ></video>-->
        <miniPlayer
          src="https://player.vimeo.com/external/557954407.hd.mp4?s=bcae68fd2d18867df4652c99a291b02b8a4f50c5&profile_id=175"
          thumbnail="thumbnail-legal.png"
        ></miniPlayer>
        <p>Conclusion</p>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page27",

  components: {
    miniPlayer,
  },

  methods: {
    goto: function (page) {
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss" scoped>
.page27 {

  top: -2.4vh;

  .page_content {
    margin: 3vw;
    display: flex;
    flex-wrap: wrap;
    height: 70vh;
    padding-top: 4vh;

    .thumbsButton {
      //width:2vw;
      height: 20vh;
      flex: 0 1 20%;
      margin: 2vw;
      border: #f1e966 solid 4px;
      overflow: hidden;
      position: initial;
      cursor:pointer;
    }

    img {
      width: 100%;
      height: 18.6vh;
      position: relative;
      z-index: 1;
      object-fit: cover;
    }

    video {
      width: 100%;
      position: initial;
    }

    p {
      background-color: #e0007a;
      color: #f1e966;
      position: relative;
      z-index: 2;
      top: -3.8vh;
      font-size: 1.2vw;
      text-align: center;
    }
  }
}
</style>