<template>
  <div class="pages page29">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/chaussee.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Circulation sur trottoirs interdite</h1>
      <p>
        Le conducteur d'EDPM circule sur le trottoir et slalome entre les
        piétons.
      </p>
      <!--<video
        controls=""
        src="https://player.vimeo.com/external/557954194.hd.mp4?s=fc3783f8c305e06e7f9f5aee892d96e793a0e839&profile_id=175"
      ></video>-->
      <miniPlayer
        src="https://player.vimeo.com/external/557954194.hd.mp4?s=fc3783f8c305e06e7f9f5aee892d96e793a0e839&profile_id=175"
        thumbnail="thumbnail-risque2.png"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <p>
          La circulation des EDPMs sur les trottoirs est interdite en raison de
          leur vitesse et de la dangerosité occasionnée pour les piétons.
        </p>
        <p>
          Sur un trottoir, l'utilisateur d'EDPM doit marcher à côté de celui-ci.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page29",

  components: {
    miniPlayer,
  },

  data() {
    return {
      showConseil: false,
    };
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page29 {
  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>