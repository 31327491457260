<template>
  <video
    controls
    preload="auto"
    :src="url"
    :poster="resolve_url(thumbnail)"
    controlslist="nodownload"
    disablePictureInPicture
    @ended="onEnd()"
    @play="onPlay($event)"
  ></video>
</template>

<script>
export default {
  name: "miniPlayer",
  props: {
    url: String,
    thumbnail: String,
  },
  methods: {
    onEnd: function () {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    },

    onPlay: function (e) {
      if (e.target.requestFullscreen) {
        e.target.requestFullscreen();
      } else if (e.target.webkitRequestFullscreen) {
        /* Safari */
        e.target.webkitRequestFullscreen();
      } else if (e.target.msRequestFullscreen) {
        /* IE11 */
        e.target.msRequestFullscreen();
      }
    },

    onFullScreen: function (e) {
      //console.log(e);
      if (document.fullscreenElement) {
        //console.log("Fullscreen enabled");
        e.target.play();
        //this.isFullScreen = true;
      } else {
        //console.log("Fullscreen disabled");
        //this.isFullScreen = false;
        e.target.pause();
      }

      //console.log(this);
    },

    resolve_url: function (path) {
      let images = require.context("../assets/", false, /\.png$|\.jpg$/);
      return images("./" + path);
    },
  },

  created: function () {
    //console.log("Created");
    document.addEventListener("webkitfullscreenchange", this.onFullScreen);
    document.addEventListener("mozfullscreenchange", this.onFullScreen);
    document.addEventListener("fullscreenchange", this.onFullScreen);
  },
};
</script>